<template>
  <div>
    <el-dialog
      :visible.sync="maximumQueryLimitShow"
      :modal-append-to-body="false"
      :before-close="handleClose"
      width="784px"
      custom-class="common-dialog"
      :close-on-click-modal="false"
    >
      <div class="title">
        <svg-icon icon-class="lead-limit-tip" class="lead-limit-tip" />
        <span>You've Reached Your Limit</span>
      </div>
      <div class="subscription-info">
        <div>
          <div>Subscription Tiers</div>
          <div class="tip">Switch plans seamlessly — your remaining credits will transfer as top-up credits for future use.</div>
        </div>
        <div class="subscription-tabs">
          <span 
            v-for="item in tabList" 
            :key="item.dataKey" 
            :class="['subscription-item', { active: item.dataKey === activeTab}]"
            @click="toggleTab(item.dataKey)"
            >
            {{ item.name }}
          </span>
        </div>
      </div>
      <div>
        <el-table
          :data="list"
          style="width: 100%"
          border=""
          row-key="name"
          default-expand-all
          class="price-table"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            v-for="(item, i) in title"
            :key="item"
            :align="i === 0 ? 'left' : 'center'"
            :prop="i === 0 ? 'name' : item"
            :label="$t(item)"
            :width="i === 0 ? 200 : ''"
          >
            <template slot-scope="{ row }">
              <span v-if="i === 0">
                <span>{{ $t(row.name) }}</span>
              </span>
              <div v-else>
                <span v-if="row[item] === -1">{{ $t("custom") }}</span>
                <span v-if="row[item] !== -1 && row[item]">
                  <svg-icon
                    class="checked-icon"
                    icon-class="titck"
                    v-if="row.dataKey === PLAN_TABLE_ITEM_CHECKED || typeof row.free === 'boolean'"
                  />
                  <span v-else>
                    <span v-if="row.dataKey === 'topup_credit_amount'">$</span>
                    <svg-icon
                      icon-class="plan-money"
                      class="icon-money"
                      v-if="
                        [
                          'data_export_credit',
                          'company_search_credit',
                          'people_search_credit',
                          'email_redeem_credit',
                          'web_intent_signal_credit',
                          'phone_number_redeem_credit',
                        ].includes(row.dataKey)
                      "
                    />
                    <span v-if="Number(row[item]) !== 0">{{ Number(row[item]).toLocaleString()}}</span>
                  </span>
                </span>
                <span v-if="row.dataKey === PLAN_TABLE_ITEM_CHECKED">
                  <svg-icon icon-class="titck" class="checked-icon" />
                </span>
                <span v-if="row.dataKey === PLAN_TABLE_ITEM_NOT_CHECKED">
                </span>
                <span v-if="row.dataKey === 'credits'">{{ calculateFieldVal('credits', i-1)}}</span>
                <span v-if="row.dataKey === 'searches'">{{ calculateFieldVal('searches', i-1) }}</span>
                <span v-if="row.dataKey === 'price'">{{ calculateFieldVal('price', i-1) }}</span>
                <span v-if="row.dataKey === 'upgradeButton'" class="btn-wrapper">
                  <el-button 
                    type="primary" 
                    :disabled="typeList[i-1].is_custom_plan || typeList[i-1].is_current"
                    @click="changePlan(typeList[i-1].subscription_identifier)"
                  >
                    {{ calculateBtnText(i-1) }}
                  </el-button>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <AddCard :visible.sync="payDialog" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddCard from '@/views/setting/upgrade/components/AddCard.vue';
import { upgradePlan } from "@/api/setting";

const TAB_MONTH = 1, TAB_ANNUAL = 2;

export default {
  props: ["dialogVisible"],
  components: {
    AddCard,
  },
  created() {
    if (!this.annualPriceList?.length && !this.monthPriceList?.length) {
      this.getSubscription();
    }
  },
  computed: {
    ...mapState("common", [
      "maximumQueryLimitShow",
      "annualPriceList",
      "monthPriceList",
      "monthPriceIndex",
      "annualPriceIndex",
    ]),
    ...mapState('user',['currentId']),
    typeList() {
      return this.activeTab === TAB_MONTH ? this.monthPriceList: this.annualPriceList;
    },
    list() {
      if (this.typeList.length) {
        return this.fieldData.map((item, i) => {
          const childProps = item.children
            ? {
                children: item.children.map((child) => ({
                  name: child.name,
                  free: this.typeList[0][child.dataKey],
                  growth: this.typeList[1][child.dataKey],
                  business: this.typeList[2][child.dataKey],
                  organization: this.typeList[3][child.dataKey],
                  dataKey: child.dataKey,
                })),
              }
            : {};
          return {
            name: item.name,
            free: this.typeList[0][item.dataKey],
            growth: this.typeList[1][item.dataKey],
            business: this.typeList[2][item.dataKey],
            organization: this.typeList[3][item.dataKey],
            dataKey: item.dataKey,
            ...childProps,
          };
        });
      }
      return [];
    },
  },
  data() {
    return {
      PLAN_TABLE_ITEM_CHECKED: "-2",
      PLAN_TABLE_ITEM_NOT_CHECKED: '-3',
      TAB_MONTH,
      TAB_ANNUAL,
      loading: false,
      tabList: [
        {
          name: 'Monthly',
          dataKey: TAB_MONTH
        },{
          name: 'Annual',
          dataKey: TAB_ANNUAL,
        },
      ],
      activeTab: TAB_ANNUAL,
      title: ["Tiers", "free", "growth", "business", "organization"],
      fieldData: [
        {
          dataKey: "credits",
          name: "credits",
        },
        {
          dataKey: "searches",
          name: "Searches",
        },
        {
          dataKey: "topup_credit_amount",
          name: "topUpCredits",
        },
        {
          dataKey: "max_search_page",
          name: "totalPageLimit",
        },
        {
          dataKey: 'max_portal_monthly_query',
          name: 'queryLimitPerMonth',
        },
        {
          dataKey: "max_export_limit",
          name: "exportLimit",
        },
        {
          name: 'Features',
          dataKey: this.PLAN_TABLE_ITEM_NOT_CHECKED,
          children: [
            {
              dataKey: "is_custom_role_permission_available",
              name: "customRolePermission",
            }, {
              dataKey: "is_team_management_available",
              name: "teamManagement",
            }, {
              name: "API",
              dataKey: "is_api_access_available",
            },
          ],
        },
        {
          dataKey: 'price',
          name: 'Price - Per user, per month Billed annually',
        }, {
          dataKey: 'upgradeButton',
          name: '',
        }
      ],
      payDialog: false,
    };
  },
  methods: {
    ...mapMutations("common", [
      "setMaximumQueryLimitShow",
      "setMonthPriceList",
      "setAnnualPriceList",
    ]),
    ...mapActions("common", ["getSubscription"]),
    toggleTab(tab) {
      this.activeTab = tab;
    },
    upgrade() {
      this.setMaximumQueryLimitShow();
    },
    handleClose() {
      this.setMaximumQueryLimitShow();
    },
    calculateFieldVal(field, i) {
      const item = this.typeList[i];
      if (field === 'credits') {
        return item.is_custom_plan ? this.$t("custom") : item.credit * Number(item.charge_period);
      } else if (field === 'searches') {
        return item.is_custom_plan ? this.$t("custom") : item.max_portal_monthly_query;
      } else if (field === 'price') {
        return item.amount === -1 ? this.$t("custom") : item.currency_symbol + item.amount
      }
    },
    calculateBtnText(i) {
      const index = this.activeTab === TAB_MONTH ? this.monthPriceIndex: this.annualPriceIndex;
      if (index === i) {
        return 'Current'
      } else if (index < i) {
        return 'Upgrade'
      } else {
        return 'Downgrade'
      }
    },
    async changePlan(subscription_identifier) {
      try {
        this.loading = true;
        await upgradePlan({
          profile_id: this.currentId.profile_id,
          subscription_identifier,
        });
        this.loading = false;
        this.$message('change successfully');
      } catch(err) {
        this.loading = false;
        if (err.response && err.response.data.code) {
          this.payDialog = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
  color: $color-black-2;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.54px;
  .lead-limit-tip {
    width: 24px;
    height: 24px;
  }
}
.subscription-info {
  display: flex;
  justify-content: space-between;
  color: $color-black-2;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
  .tip {
    color: $color-gray-1;
    font-size: 12px;
    line-height: 18px;
  }
  .subscription-tabs {
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: $color-gray-3;
    color: $color-gray-1;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.36px;
    border-radius: 8px;
    cursor: pointer;
    .subscription-item {
      padding: 6px 16px;
      border: 1px solid $color-gray-3;
      border-radius: 6px;
      &.active {
        background-color: #fff;
        box-shadow: 0px 0px 8px 0px rgba(24, 35, 106, 0.08);
      }
    }
  }
}
.price-table {
  ::v-deep .el-table__body-wrapper {
    .el-table__row:last-child {
      .el-table__cell {
        padding: 0;
        .cell {
          padding: 0;
        }
      }
    }
  }
  .btn-wrapper {
    width: 100%;
    padding: 0;
    .el-button {
      width: 100%;
      border-radius: 0;
    }
  }
}
</style>