import { getEmployeeList } from "@/api/website-intent";
import { REDEEM_EMAIL, REDEEM_PHONE } from '@/views/search/constants';

const state = {
  employeeFilterParams: {
    verticals: [],
    technologies: [],
    people_titles: [],
    people_groups: [],
    people_name: null,
    people_locations: [],
    department_functions: [],
    departments: [],
  },
  employeeTempParams: {
    verticals: [],
    technologies: [],
    people_titles: [],
    people_groups: [],
    people_name: null,
    people_locations: [],
    department_functions: [],
    departments: [],
  },
  tableLoading: false,
  lists: [],
  checkedList: [],
  multipleSelectedListIds: [],
  paginationInfo: {
    page: 1,
    per_page: 25,
    total_entries: 0,
  },
}

const mutations = {
  setTableLoading(state, payload) {
    state.tableLoading = payload;
  },
  setList(state, payload) {
    state.lists = payload;
  },
  setCheckedList(state, payload) {
    state.checkedList = payload;
  },
  setPaginationInfo(state, payload) {
    state.paginationInfo = payload;
  },
  setEmployeeFilterParams(state, payload) {
    state.employeeFilterParams = payload;
  },
  setEmployeeTempParams(state, payload) {
    for(let key in payload) {
      state.employeeTempParams[key] = payload[key];
    } 
  },
  resetEmployeeFilterParams(state) {
    state.employeeFilterParams = {
      verticals: [],
      technologies: [],
      people_titles: [],
      companies: [],
      people_groups: [],
      people_name: null,
      people_locations: [],
      employees: [],
      department_functions: [],
      departments: [],
    }
  },
  setMultiEmployeeAddSavedList(state, addedGroupInfo) {
    const ids = state.checkedList.map((item) => item.people_search_id);
    state.lists = state.lists.map((item) => {
      if (ids.includes(item.people_search_id)) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((group) => group.group_id);
          return groupIds.includes(addedGroupInfo.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, addedGroupInfo],
          }
        } else {
          return {
            ...item,
            saved_lists: [addedGroupInfo]
          }
        }
      } else {
        return item;
      }
    })
  },
  setMultipleSelectedListIds(state, payload) {
    state.multipleSelectedListIds = payload;
  },
  setIntentRowAddSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.lists = state.lists.map((item) => {
      if (item.people_search_id === people_search_id) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((groupItem) => groupItem.group_id);
          return groupIds.includes(group.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, group],
          }
        } else {
          return {
            ...item,
            saved_lists: [group],
          }
        }
      } else {
        return item;
      };
    })
  },
  setIntentRowDeleteSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.lists = state.lists.map((item) => {
      if (item.people_search_id === people_search_id) {
        return {
          ...item,
          saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== group.group_id),
        }
      } else {
        return item;
      }
    })
  },
  setMultiEmployeeDeleteSavedList(state, addedGroupInfo) {
    const ids = state.checkedList.map((item) => item.people_search_id);
    state.lists = state.lists.map((item) => {
      if (ids.includes(item.people_search_id)) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((group) => group.group_id);
          return groupIds.includes(addedGroupInfo.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, addedGroupInfo],
          }
        } else {
          return {
            ...item,
            saved_lists: [addedGroupInfo]
          }
        }
      } else {
        return item;
      }
    })
  },
  updateTableContact(state, payload) {
    const { row, people_search_id, type } = payload;
    state.lists = state.lists.map((item) => {
      if (item.people_search_id === people_search_id) {
        if (type === REDEEM_EMAIL) {
          return {
            ...item,
            contacts: [
              ...(item.contacts ? item.contacts?.filter((item) => item.type === 'phone'): []),
              ...(row.emails
                ? row.emails.map((item) => ({
                    ...item,
                    is_redeemed: true,
                  }))
                : []),
            ]
          }
        } else if (type === REDEEM_PHONE) {
          return {
            ...item,
            contacts: [
              ...(item.contacts ? item.contacts.filter((item) => item.type === 'email-personal' || item.type === 'email-work'): []),
              ...(row.phones
                ? row.phones.map((item) => ({
                    ...item,
                    is_redeemed: true,
                  }))
                : []),
            ]
          }
        }
      } else {
        return item;
      }
    });
  }
}

const actions = { 
  async getEmployees({ commit }, params) {
    try {
      commit('setTableLoading', true);
      const { data } = await getEmployeeList(params);
      if (data && data.pagination) {
        commit('setList', data.peoples ? data.peoples.map((item) => ({
          ...item,
          isContactOpen: false,
          isAddOpen: false,
          isPhoneOpen: false,
          isSaveOpen: false
        })): [])
        commit('setPaginationInfo', data.pagination);
        commit('setTableLoading', false);
        return data?.pagination.total_entries;
      }
      commit('setTableLoading', false);
      return 0;
    } catch (e) {
      commit('setTableLoading', false);
    }
  },
}
 
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
