
import { getCompanyGroupList, getPeopleGroupList } from '@/api/contact';
import { PEOPLE_TYPE, COMPANY_TYPE, INTENT_TYPE } from '@/views/search/constants';

const state = {
	tempFilterList: [],
	//-------------新UI逻辑------------
	companySavedList: [],
	peopleSavedList: [],
	intentSavedList: [],
	intentSavedListEmpty: false,
	savedListPeopleFilter: {
		type: PEOPLE_TYPE,
		isPersonal: null,
		name: '',
		is_ascending_order: false,
	},
	savedListCompanyFilter: {
		type: COMPANY_TYPE,
		isPersonal: null,
		name: '',
		is_ascending_order: false,
	},
	savedListIntentFilter: {
		type: INTENT_TYPE,
		isPersonal: null,
		name: '',
		is_ascending_order: false,
	}
};

const mutations = {
	//-------------新UI逻辑开始------------
	setCompanyList(state, payload) {
		state.companySavedList = payload;
	},
	setPeopleList(state, payload) {
		state.peopleSavedList = payload;
	},
	setIntentList(state, payload) {
		state.intentSavedList = payload;
	},
	setSavedListPeopleFilter(state, payload) {
		state.savedListPeopleFilter = {
			...state.savedListPeopleFilter,
			...payload,
		}
	},
	setSavedListCompanyFilter(state, payload) {
		state.savedListCompanyFilter = {
			...state.savedListCompanyFilter,
			...payload,
		}
	},
	//-------------新UI逻辑结束------------
	setTempFilterList(state, payload) {
		state.tempFilterList = payload;
	},
};

const actions = {
	async getSavedList({ commit,state, rootState }, data) {
		try {
			const { type, isPersonal, name, is_ascending_order } = data;
			const params = {
				profile_id: rootState.user.currentId.profile_id,
				is_ascending_order: is_ascending_order,
				is_personal: isPersonal,
				name,
				groups:[],
				peoples:[]
			};
			if (type === PEOPLE_TYPE) {
				const result = await getPeopleGroupList(params);
				commit('setPeopleList', result.data);
				return result.data.length === 0;
			} else if (type === COMPANY_TYPE) {
				const result = await getCompanyGroupList(params);
				commit('setCompanyList', result.data);
				return result.data.length === 0;
			} else if (type === INTENT_TYPE) {
				console.log('oooo');
				
				const result = await getPeopleGroupList(params);
				commit('setIntentList', result.data);
				console.log(result.data)
				return result.data.length === 0;
			}
		} catch(err) {

		}
	},
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}