<template>
  <el-dialog
    :visible.sync="visible"
    width="417px"
    custom-class="pay-dialog"
    :before-close="handlePayDialogClose"
  >
    <div class="pay-title">Add a Payment Method</div>
    <div class="pay-content">
      To start your subscription, please add your credit card details.
    </div>
    <div class="btns-wrapper">
      <el-button class="btn-group-1" @click="handlePayDialogClose">
        {{ $t("Cancel") }}
      </el-button>
      <el-button class="btn-group-2" @click="addCard"> Add Card </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['visible'],
  methods: {
    addCard() {
      this.$router.push({name: 'billing'})
    },
    handlePayDialogClose() {
      this.$emit('update:visible', false)
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .pay-dialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 32px;
    text-align: center;
    .pay-title {
      margin-bottom: 24px;
      color: $color-blue-8;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0.72px;
    }
    .pay-content {
      margin-bottom: 20px;
      color: #343434;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.42px;
    }
    .btns-wrapper {
      display: flex;
      gap: 5px;
      .el-button {
        flex: 1;
      }
    }
  }
}
</style>
