import { render, staticRenderFns } from "./notification-item.vue?vue&type=template&id=380cdae2&scoped=true"
import script from "./notification-item.vue?vue&type=script&lang=js"
export * from "./notification-item.vue?vue&type=script&lang=js"
import style0 from "./notification-item.vue?vue&type=style&index=0&id=380cdae2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380cdae2",
  null
  
)

export default component.exports